<template>
  <div class="personal-container">
    <div class="personal-header">
      <div class="left-image">
        <div class="header-image">
          <van-image round width="80" height="80" :src="state.headimgurl" />
        </div>
        <div class="nick-name">{{ state.nickname }}</div>
      </div>
    </div>

    <div class="my-contents">
      <div class="title">我的内容</div>
      <div class="contents-list">
        <div
          class="details"
          :key="item.title"
          v-for="item in state.contentList"
          @click="contentRouter(item.route)"
        >
          <div class="inside">
            <v-iconFontSymbol :icon="item.icon" :style="item.fontSize" />
            <span class="inside-title">{{ item.title }}</span>
          </div>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { getCurrentInstance, onMounted, reactive } from "vue";

import personalApi from "@/api/personal";
import IconFontSymbol from "@/components/IconFontSymbol.vue";

const fontSize = { "font-size": "0.25rem" };
export default {
  name: "Personal",
  components: {
    "v-iconFontSymbol": IconFontSymbol,
  },
  setup() {
    const state = reactive({
      nickname: "",
      headimgurl: "",
      contentList: [
        {
          fontSize,
          icon: "icontime",
          title: "观看历史",
          route: "/history",
        },
        {
          fontSize,
          icon: "iconcollect",
          title: "我的收藏",
          route: "/myCollection",
        },
        {
          fontSize: {
            "font-size": "0.28rem",
          },
          icon: "iconmy_bei",
          title: "我的云贝",
          route: "/signList",
        },
        {
          fontSize,
          icon: "icongift_bag",
          title: "邀请有礼",
          route: "/inviteShare",
        },
      ],
    });
    const router = useRouter();
    const { proxy } = getCurrentInstance();

    const contentRouter = (name) => {
      let userInfo = proxy.$store.state.userInfo;
      let routePath =
        name === "/inviteShare"
          ? name + `${userInfo.user_info ? "/" + userInfo.user_info : ""}`
          : name;
      router.push(routePath);
    };

    const getAvater = async (unionid) => {
      const res = await proxy.$request.handleFetchGet(
        `${personalApi.userinfo}?unionid=${unionid}`
      );
      state.nickname = res.nickname;
      state.headimgurl = res.headimgurl;
    };

    if (
      !proxy.$store.state.userInfo ||
      !proxy.$store.state.userInfo.user_info
    ) {
      proxy.$store.dispatch("getUserInfoAction");
    }

    onMounted(() => {
      let unionid = proxy.$cookie.getCookie("unionid");
      getAvater(unionid);
    });

    return { state, contentRouter };
  },
};
</script>
